.list-header input[search="false"] {
    display: none;
}

.list-header ul {
    display: flex;
    padding: 0;
    margin-top: 5px;
    margin-bottom: 8px;
}

.list-header ul:empty {
    display: none;
}

.list-header li {
    width: 32px;
    min-width: 32px;
    height: 32px;
    font-size: 0.8em;
    border-radius: 32px;
    line-height: 32px;
    text-align: center;
    background-color: #eee;
    margin: 2px;
    cursor: pointer;
    list-style: none;
}

.list-header li[selected="true"] {
    font-weight: bold;
}

.list-content:empty {
    padding: 8px;
    position: relative;
}

.list-content:empty::before {
    content: attr(data-message);
}

.lines .list-content:empty::before {
    text-align: center;
}